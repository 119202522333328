// external imports
import { Switch, Route } from "react-router-dom";
import AOS from "aos";


// asset
import "./Assets/App.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "aos/dist/aos.css";

// internal imports
import BackToTop from "./Components/Helpers/BackToTop";
import PageNotFound from "./Pages/PageNotFound";
import TermsOfService from "./Pages/TermsOfService";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import HomeV2 from "./Pages/HomeV2";
import Footer from "./Components/HomeV2/Footer";
import { useContext } from "react";
import { HelperContext } from "./Context/Helper";
import AccountDeletion from "./Pages/AccountDeletion";
import WhatsApp from "./Components/Helpers/WhatsApp";

AOS.init();

function App() {
  const { checkScroll } = useContext(HelperContext);

  checkScroll();

  return (
    <>
      {/* <Navigation /> */}
      <Switch>
        <Route exact path="/" component={HomeV2} />
        <Route exact path="/account-deletion" component={AccountDeletion} />
        <Route exact path="/terms-of-use" component={TermsOfService} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="*" component={PageNotFound} />
      </Switch>

      <Footer />

      <WhatsApp />
      <BackToTop />
    </>
  );
}

export default App;
