import React from "react";
import { Box } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

export default function WhatsApp() {
  return (
    <Box
      component={"a"}
      href="https://wa.me/2348067723579"
      class="whatsapp_float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <WhatsAppIcon />
    </Box>
  );
}
